export const cadinuTradingCompetition = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  { inputs: [{ internalType: 'address', name: 'owner', type: 'address' }], name: 'OwnableInvalidOwner', type: 'error' },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'OwnableUnauthorizedAccount',
    type: 'error',
  },
  { inputs: [], name: '__errorCannotWithdrawCbon', type: 'error' },
  { inputs: [], name: '__errorCompetitionHasEnded', type: 'error' },
  { inputs: [], name: '__errorCompetitionHasNotEnded', type: 'error' },
  { inputs: [], name: '__errorNoReward', type: 'error' },
  { inputs: [], name: '__errorNotSupportedFactory', type: 'error' },
  { inputs: [], name: '__errorRewardAlreadySet', type: 'error' },
  { inputs: [], name: '__errorWrongCompetitionId', type: 'error' },
  { inputs: [], name: '__errorWrongInput', type: 'error' },
  { inputs: [], name: '__errorWrongPool', type: 'error' },
  { inputs: [], name: '__errorWrongReward', type: 'error' },
  { inputs: [], name: '__errorWrongTimeSetting', type: 'error' },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'factoryAddress', type: 'address' },
      { indexed: false, internalType: 'bool', name: 'isSupported', type: 'bool' },
    ],
    name: 'FactorySetting',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'startTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'endTime', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'poolAddress', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'numberOfWinners', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'rewardAmount', type: 'uint256' },
    ],
    name: 'NewCompetitionLaunched',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'competitionId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'increasedAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newReward', type: 'uint256' },
    ],
    name: 'RewardIncreased',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'competitionId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'rewardCharged', type: 'uint256' },
    ],
    name: 'RewardSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'startTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'endTime', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'pool', type: 'address' },
    ],
    name: 'StartCompetition',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'withdrawnAmount', type: 'uint256' },
    ],
    name: 'WithdrawReward',
    type: 'event',
  },
  {
    inputs: [],
    name: 'CBON',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'cadinuCompetitions',
    outputs: [
      { internalType: 'uint256', name: 'startTime', type: 'uint256' },
      { internalType: 'uint256', name: 'endTime', type: 'uint256' },
      { internalType: 'address', name: 'poolAddress', type: 'address' },
      { internalType: 'uint256', name: 'numberOfWinners', type: 'uint256' },
      { internalType: 'uint256', name: 'rewardAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'rewardCharged', type: 'uint256' },
      { internalType: 'bool', name: 'isBoosted', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_factoryAddress', type: 'address' },
      { internalType: 'bool', name: '_isSupported', type: 'bool' },
    ],
    name: 'factorySetting',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_competitionId', type: 'uint256' }],
    name: 'getWinners',
    outputs: [
      { internalType: 'address[]', name: '', type: 'address[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_competitionId', type: 'uint256' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
    ],
    name: 'increaseReward',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'lastId',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'nextId',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'uint256', name: '_competitionId', type: 'uint256' },
      { internalType: 'address[]', name: '_users', type: 'address[]' },
      { internalType: 'uint256[]', name: '_rewards', type: 'uint256[]' },
    ],
    name: 'setReward',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_startTime', type: 'uint256' },
      { internalType: 'uint256', name: '_endTime', type: 'uint256' },
      { internalType: 'address', name: '_poolAddress', type: 'address' },
      { internalType: 'uint256', name: '_numberOfWinners', type: 'uint256' },
      { internalType: 'uint256', name: '_rewardAmount', type: 'uint256' },
    ],
    name: 'startCompetition',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'supportedFactories',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'users',
    outputs: [
      { internalType: 'uint256', name: 'availableReward', type: 'uint256' },
      { internalType: 'uint256', name: 'withdrawnReward', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'withdrawReward', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'address', name: '_tokenAddress', type: 'address' }],
    name: 'withdrawWrongToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const
