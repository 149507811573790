export const CadinuLevelNftsAbi = [
    {
"inputs":[{
"internalType":"uint256",
"name":"_level",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_maxSupply",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_priceInCbon",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_referralDiscount",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_referralPortion",
"type":"uint256"
},
{
"internalType":"address",
"name":"_cadinuReferral",
"type":"address"
},
{
"internalType":"string",
"name":"baseTokenURI",
"type":"string"
}],
"stateMutability":"nonpayable",
"type":"constructor"
},
{
"inputs":[],
"name":"__errorMintingZeroNft",
"type":"error"
},
{
"inputs":[],
"name":"__errorQueryForNonexistentToken",
"type":"error"
},
{
"inputs":[],
"name":"__errorReachedMaxSupply",
"type":"error"
},
{
"anonymous":false,
"inputs":[{
"indexed":true,
"internalType":"address",
"name":"owner",
"type":"address"
},
{
"indexed":true,
"internalType":"address",
"name":"approved",
"type":"address"
},
{
"indexed":true,
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
}],
"name":"Approval",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":true,
"internalType":"address",
"name":"owner",
"type":"address"
},
{
"indexed":true,
"internalType":"address",
"name":"operator",
"type":"address"
},
{
"indexed":false,
"internalType":"bool",
"name":"approved",
"type":"bool"
}],
"name":"ApprovalForAll",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":false,
"internalType":"address",
"name":"buyer",
"type":"address"
},
{
"indexed":false,
"internalType":"uint256",
"name":"amount",
"type":"uint256"
}],
"name":"BuyNfts",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":true,
"internalType":"address",
"name":"previousOwner",
"type":"address"
},
{
"indexed":true,
"internalType":"address",
"name":"newOwner",
"type":"address"
}],
"name":"OwnershipTransferred",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":false,
"internalType":"uint256",
"name":"cbonPrice",
"type":"uint256"
}],
"name":"PriceSet",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":false,
"internalType":"uint256",
"name":"referralPortion",
"type":"uint256"
}],
"name":"ReferralPortionSet",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":true,
"internalType":"address",
"name":"from",
"type":"address"
},
{
"indexed":true,
"internalType":"address",
"name":"to",
"type":"address"
},
{
"indexed":true,
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
}],
"name":"Transfer",
"type":"event"
},
{
"inputs":[{
"internalType":"address",
"name":"to",
"type":"address"
},
{
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
}],
"name":"approve",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"owner",
"type":"address"
}],
"name":"balanceOf",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
}],
"name":"burn",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"_to",
"type":"address"
},
{
"internalType":"uint256",
"name":"_amount",
"type":"uint256"
},
{
"internalType":"address",
"name":"_referral",
"type":"address"
}],
"name":"buyNFTs",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[],
"name":"cadinuReferral",
"outputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"cbon",
"outputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
}],
"name":"getApproved",
"outputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"owner",
"type":"address"
},
{
"internalType":"address",
"name":"operator",
"type":"address"
}],
"name":"isApprovedForAll",
"outputs":[{
"internalType":"bool",
"name":"",
"type":"bool"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"level",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"maxSupply",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"name",
"outputs":[{
"internalType":"string",
"name":"",
"type":"string"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"owner",
"outputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
}],
"name":"ownerOf",
"outputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"priceInCbon",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"referralDiscount",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"referralPortion",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_referralPortion",
"type":"uint256"
}],
"name":"referralSetting",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[],
"name":"renounceOwnership",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"from",
"type":"address"
},
{
"internalType":"address",
"name":"to",
"type":"address"
},
{
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
}],
"name":"safeTransferFrom",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"from",
"type":"address"
},
{
"internalType":"address",
"name":"to",
"type":"address"
},
{
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
},
{
"internalType":"bytes",
"name":"data",
"type":"bytes"
}],
"name":"safeTransferFrom",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"operator",
"type":"address"
},
{
"internalType":"bool",
"name":"approved",
"type":"bool"
}],
"name":"setApprovalForAll",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"string",
"name":"baseURI",
"type":"string"
}],
"name":"setBaseURI",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_nftPrice",
"type":"uint256"
}],
"name":"setPrice",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"bytes4",
"name":"interfaceId",
"type":"bytes4"
}],
"name":"supportsInterface",
"outputs":[{
"internalType":"bool",
"name":"",
"type":"bool"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"symbol",
"outputs":[{
"internalType":"string",
"name":"",
"type":"string"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"index",
"type":"uint256"
}],
"name":"tokenByIndex",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"owner",
"type":"address"
},
{
"internalType":"uint256",
"name":"index",
"type":"uint256"
}],
"name":"tokenOfOwnerByIndex",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
}],
"name":"tokenURI",
"outputs":[{
"internalType":"string",
"name":"",
"type":"string"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"_owner",
"type":"address"
}],
"name":"tokensOfOwner",
"outputs":[{
"internalType":"uint256[]",
"name":"",
"type":"uint256[]"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"totalSupply",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"from",
"type":"address"
},
{
"internalType":"address",
"name":"to",
"type":"address"
},
{
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
}],
"name":"transferFrom",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"newOwner",
"type":"address"
}],
"name":"transferOwnership",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
}
] as const