export const cadinuProfileRewardAbi = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  { inputs: [{ internalType: 'address', name: 'owner', type: 'address' }], name: 'OwnableInvalidOwner', type: 'error' },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'OwnableUnauthorizedAccount',
    type: 'error',
  },
  { inputs: [], name: '__errorCampaignIsEnded', type: 'error' },
  { inputs: [], name: '__errorCannotBoostCampaign', type: 'error' },
  { inputs: [], name: '__errorExclusiveRewardIsTaken', type: 'error' },
  { inputs: [], name: '__errorIndexOutOfRange', type: 'error' },
  { inputs: [], name: '__errorUserNotRegistered', type: 'error' },
  { inputs: [], name: '__errorUserPointsIsNotEnough', type: 'error' },
  { inputs: [], name: '__errorWrongInputData', type: 'error' },
  { inputs: [], name: '__errorZeroIncrease', type: 'error' },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'id', type: 'uint256' }],
    name: 'CampaignEnded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'id', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'globalIncreaseAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'exclusiveIncreaseAmount', type: 'uint256' },
    ],
    name: 'CampaignIsBoosted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'id', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'totalAmountCharged', type: 'uint256' },
    ],
    name: 'CampaignIsCharged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'id', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'rewardToken', type: 'address' },
    ],
    name: 'CampaignStarted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'campaignId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'rewardToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'ExclusiveRewardWithdrawn',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'campaignId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'rewardToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'GlobalRewardWithdrawn',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [],
    name: 'PROFILE',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: '_increaseGlobal', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '_increaseExclusive', type: 'uint256[]' },
    ],
    name: 'boostCampaign',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'cadinuCampaign',
    outputs: [
      { internalType: 'address', name: 'rewardToken', type: 'address' },
      { internalType: 'uint256', name: 'exclusiveWinnerCount', type: 'uint256' },
      { internalType: 'uint256', name: 'globalWinnerCount', type: 'uint256' },
      { internalType: 'uint256', name: 'chargedAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'withdrawnAmount', type: 'uint256' },
      { internalType: 'bool', name: 'isStarted', type: 'bool' },
      { internalType: 'bool', name: 'isBoosted', type: 'bool' },
      { internalType: 'bool', name: 'isEnded', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_user', type: 'address' },
      { internalType: 'uint256', name: '_currentId', type: 'uint256' },
      { internalType: 'uint256', name: '_index', type: 'uint256' },
    ],
    name: 'calculateExclusiveRewardData',
    outputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'uint256', name: 'pointsUsed', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_user', type: 'address' },
      { internalType: 'uint256', name: '_currentId', type: 'uint256' },
      { internalType: 'uint256', name: '_index', type: 'uint256' },
    ],
    name: 'calculateGlobalRewardData',
    outputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'uint256', name: 'pointsUsed', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }],
    name: 'chargeCampaign',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_id', type: 'uint256' }],
    name: 'getCampaignDataArray',
    outputs: [
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'bool[]', name: '', type: 'bool[]' },
      { internalType: 'address[]', name: '', type: 'address[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
    name: 'getUserUnusedPoints',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'nextId',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'address', name: '_rewardToken', type: 'address' },
      { internalType: 'uint256', name: '_exclusiveWinnerCount', type: 'uint256' },
      { internalType: 'uint256[]', name: '_exclusiveWinnerRewards', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '_exclusiveWinnerPoints', type: 'uint256[]' },
      { internalType: 'uint256', name: '_globalWinnerCount', type: 'uint256' },
      { internalType: 'uint256[]', name: '_globalWinnerRewards', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '_globalWinnerPoints', type: 'uint256[]' },
    ],
    name: 'startCampaign',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'users',
    outputs: [
      { internalType: 'uint256', name: 'totalClaimedPoints', type: 'uint256' },
      { internalType: 'uint256', name: 'totalClaimReward', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_index', type: 'uint256' }],
    name: 'withdrawExclusiveReward',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_index', type: 'uint256' }],
    name: 'withdrawGlobalReward',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_token', type: 'address' }],
    name: 'withdrawWrongToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const
