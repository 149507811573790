export const cadinuProfileAbi = [
    {
"inputs":[],
"stateMutability":"nonpayable",
"type":"constructor"
},
{
"inputs":[],
"name":"__errorCannotWithdrawCbon",
"type":"error"
},
{
"inputs":[],
"name":"__errorInvalidTeamId",
"type":"error"
},
{
"inputs":[],
"name":"__errorNotCadinuLevelNft",
"type":"error"
},
{
"inputs":[],
"name":"__errorNotNftOwner",
"type":"error"
},
{
"inputs":[],
"name":"__errorUserAlreadyRegistered",
"type":"error"
},
{
"inputs":[],
"name":"__errorUserIsActive",
"type":"error"
},
{
"inputs":[],
"name":"__errorUserNotActive",
"type":"error"
},
{
"inputs":[],
"name":"__errorUserNotRegistered",
"type":"error"
},
{
"inputs":[],
"name":"__errorWrongInput",
"type":"error"
},
{
"inputs":[],
"name":"__errorWrongLevel",
"type":"error"
},
{
"inputs":[],
"name":"__errorWrongReferralPortion",
"type":"error"
},
{
"anonymous":false,
"inputs":[{
"indexed":false,
"internalType":"uint256",
"name":"level",
"type":"uint256"
},
{
"indexed":false,
"internalType":"address",
"name":"nft",
"type":"address"
}],
"name":"LevelAdded",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":true,
"internalType":"address",
"name":"previousOwner",
"type":"address"
},
{
"indexed":true,
"internalType":"address",
"name":"newOwner",
"type":"address"
}],
"name":"OwnershipTransferred",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":false,
"internalType":"address",
"name":"user",
"type":"address"
},
{
"indexed":false,
"internalType":"uint256",
"name":"point",
"type":"uint256"
}],
"name":"PointAdded",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":false,
"internalType":"address",
"name":"user",
"type":"address"
},
{
"indexed":false,
"internalType":"address",
"name":"nftAddress",
"type":"address"
},
{
"indexed":false,
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
},
{
"indexed":false,
"internalType":"uint256",
"name":"level",
"type":"uint256"
}],
"name":"ProfileCreated",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":false,
"internalType":"address",
"name":"user",
"type":"address"
},
{
"indexed":false,
"internalType":"address",
"name":"nftAddress",
"type":"address"
},
{
"indexed":false,
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
},
{
"indexed":false,
"internalType":"uint256",
"name":"level",
"type":"uint256"
}],
"name":"ProfileReactivate",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":false,
"internalType":"address",
"name":"user",
"type":"address"
}],
"name":"UserPause",
"type":"event"
},
{
"anonymous":false,
"inputs":[{
"indexed":false,
"internalType":"address",
"name":"user",
"type":"address"
},
{
"indexed":false,
"internalType":"address",
"name":"nftAddress",
"type":"address"
},
{
"indexed":false,
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
},
{
"indexed":false,
"internalType":"uint256",
"name":"level",
"type":"uint256"
}],
"name":"UserUpdate",
"type":"event"
},
{
"inputs":[],
"name":"CBON",
"outputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"LEVEL_POINT",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"MIN_REFERRAL_PORTION",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"REFERRAL_DISCOUNT",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"REFERRAL_LEVEL_POINT",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_nftNumbers",
"type":"uint256"
},
{
"internalType":"uint256[]",
"name":"_nftPrices",
"type":"uint256[]"
},
{
"internalType":"uint256[]",
"name":"_maxSupply",
"type":"uint256[]"
},
{
"internalType":"uint256[]",
"name":"_referralPortion",
"type":"uint256[]"
},
{
"internalType":"string[]",
"name":"_baseTokenUri",
"type":"string[]"
}],
"name":"addLevel",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"_user",
"type":"address"
},
{
"internalType":"uint256",
"name":"_point",
"type":"uint256"
}],
"name":"addPoint",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[],
"name":"cadinuReferral",
"outputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"_nftAddress",
"type":"address"
},
{
"internalType":"uint256",
"name":"_tokenId",
"type":"uint256"
}],
"name":"createProfile",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_level",
"type":"uint256"
}],
"name":"getNftAddressesForLevel",
"outputs":[{
"internalType":"address[]",
"name":"",
"type":"address[]"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_level",
"type":"uint256"
}],
"name":"getNumberOfNFTsForLevel",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_teamId",
"type":"uint256"
}],
"name":"getTeamProfile",
"outputs":[{
"internalType":"string",
"name":"",
"type":"string"
},
{
"internalType":"string",
"name":"",
"type":"string"
},
{
"internalType":"uint256",
"name":"",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"",
"type":"uint256"
},
{
"internalType":"bool",
"name":"",
"type":"bool"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"_userAddress",
"type":"address"
}],
"name":"getUserProfile",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"",
"type":"uint256"
},
{
"internalType":"address",
"name":"",
"type":"address"
},
{
"internalType":"uint256",
"name":"",
"type":"uint256"
},
{
"internalType":"bool",
"name":"",
"type":"bool"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"_userAddress",
"type":"address"
}],
"name":"getUserStatus",
"outputs":[{
"internalType":"bool",
"name":"",
"type":"bool"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"name":"isRegistered",
"outputs":[{
"internalType":"bool",
"name":"",
"type":"bool"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"name":"levelMembers",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"name":"levels",
"outputs":[{
"internalType":"contract CadinuLevelNft",
"name":"",
"type":"address"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"name":"nftAddressToIndex",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"name":"nftAddressToLevel",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"numberOfActiveProfile",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"numberOfLevels",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"",
"type":"address"
},
{
"internalType":"address",
"name":"",
"type":"address"
},
{
"internalType":"uint256",
"name":"",
"type":"uint256"
},
{
"internalType":"bytes",
"name":"",
"type":"bytes"
}],
"name":"onERC721Received",
"outputs":[{
"internalType":"bytes4",
"name":"",
"type":"bytes4"
}],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"name":"otherPoints",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"owner",
"outputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[],
"name":"pauseProfile",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"_nftAddress",
"type":"address"
},
{
"internalType":"uint256",
"name":"_tokenId",
"type":"uint256"
}],
"name":"reactivateProfile",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[],
"name":"renounceOwnership",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"_cadinuReferral",
"type":"address"
}],
"name":"setCadinuReferralAddress",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"newOwner",
"type":"address"
}],
"name":"transferOwnership",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_level",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_newNftPriceInCbon",
"type":"uint256"
}],
"name":"updateLevelPrice",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_level",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_index",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_newNftPriceInCbon",
"type":"uint256"
}],
"name":"updateLevelPrice",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"_nftAddress",
"type":"address"
},
{
"internalType":"uint256",
"name":"_tokenId",
"type":"uint256"
}],
"name":"updateProfile",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_level",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_referralPortion",
"type":"uint256"
}],
"name":"updateReferralSetting",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_level",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_index",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_referralPortion",
"type":"uint256"
}],
"name":"updateReferralSetting",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_referralPortion",
"type":"uint256"
}],
"name":"updateReferralSetting",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[{
"internalType":"uint256",
"name":"_level",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"_index",
"type":"uint256"
},
{
"internalType":"string",
"name":"_uri",
"type":"string"
}],
"name":"updateUri",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
},
{
"inputs":[],
"name":"userCounter",
"outputs":[{
"internalType":"uint256",
"name":"",
"type":"uint256"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"",
"type":"address"
}],
"name":"users",
"outputs":[{
"internalType":"uint256",
"name":"userId",
"type":"uint256"
},
{
"internalType":"address",
"name":"nftAddress",
"type":"address"
},
{
"internalType":"uint256",
"name":"tokenId",
"type":"uint256"
},
{
"internalType":"uint256",
"name":"level",
"type":"uint256"
},
{
"internalType":"bool",
"name":"isActive",
"type":"bool"
}],
"stateMutability":"view",
"type":"function"
},
{
"inputs":[{
"internalType":"address",
"name":"_token",
"type":"address"
}],
"name":"withdrawWrongToken",
"outputs":[],
"stateMutability":"nonpayable",
"type":"function"
}] as const