export const cadinuTradingCompetitionV2 = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  { inputs: [{ internalType: 'address', name: 'owner', type: 'address' }], name: 'OwnableInvalidOwner', type: 'error' },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'OwnableUnauthorizedAccount',
    type: 'error',
  },
  { inputs: [], name: '__errorCompetitionHasEnded', type: 'error' },
  { inputs: [], name: '__errorCompetitionHasNotEnded', type: 'error' },
  { inputs: [], name: '__errorFailToSendETH', type: 'error' },
  { inputs: [], name: '__errorFailToSendReferralPortion', type: 'error' },
  { inputs: [], name: '__errorInsufficientEtherSent', type: 'error' },
  { inputs: [], name: '__errorNoReward', type: 'error' },
  { inputs: [], name: '__errorNoRewardForThisCompetition', type: 'error' },
  { inputs: [], name: '__errorNotSupportedFactory', type: 'error' },
  { inputs: [], name: '__errorProjectTokenIsNotInPool', type: 'error' },
  { inputs: [], name: '__errorRewardAlreadySet', type: 'error' },
  { inputs: [], name: '__errorRewardNotSetForThisCompetition', type: 'error' },
  { inputs: [], name: '__errorWinnerCount', type: 'error' },
  { inputs: [], name: '__errorWrongCompetitionId', type: 'error' },
  { inputs: [], name: '__errorWrongCompetitionType', type: 'error' },
  { inputs: [], name: '__errorWrongCostSetting', type: 'error' },
  { inputs: [], name: '__errorWrongInput', type: 'error' },
  { inputs: [], name: '__errorWrongPool', type: 'error' },
  { inputs: [], name: '__errorWrongReferralPortion', type: 'error' },
  { inputs: [], name: '__errorWrongReward', type: 'error' },
  { inputs: [], name: '__errorWrongTimeSetting', type: 'error' },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'competitionId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'increasedAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newReward', type: 'uint256' },
    ],
    name: 'CompetitionBoosted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'index', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'competitionTypeName', type: 'string' },
    ],
    name: 'CompetitionTypeSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'cost', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'costWithReferral', type: 'uint256' },
    ],
    name: 'Cost',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'factoryAddress', type: 'address' },
      { indexed: false, internalType: 'bool', name: 'isSupported', type: 'bool' },
    ],
    name: 'FactorySetting',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'startTime', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'endTime', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'poolAddress', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'rewardAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'competitionTypeIndex', type: 'uint256' },
    ],
    name: 'NewCompetitionLaunched',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'owner', type: 'address' },
      { indexed: false, internalType: 'address', name: 'projectToken', type: 'address' },
    ],
    name: 'OwnerVerified',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'referralAddress', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'referralPortion', type: 'uint256' },
    ],
    name: 'ReferralSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'competitionId', type: 'uint256' }],
    name: 'RewardSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'withdrawAmount', type: 'uint256' },
    ],
    name: 'WithdrawReward',
    type: 'event',
  },
  {
    inputs: [],
    name: 'COST',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'COST_WITH_REFERRAL',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MAX_COMPETITION_TIME',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MAX_NUMBER_OF_WINNERS',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'MIN_COMPETITION_TIME',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_referralAddress', type: 'address' },
      { internalType: 'uint256', name: '_referralPortion', type: 'uint256' },
    ],
    name: 'addReferrals',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_competitionId', type: 'uint256' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
    ],
    name: 'boostCompetition',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'competitionType',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_cost', type: 'uint256' },
      { internalType: 'uint256', name: '_costWithReferral', type: 'uint256' },
    ],
    name: 'costOfCompetition',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_factoryAddress', type: 'address' },
      { internalType: 'bool', name: '_isSupported', type: 'bool' },
    ],
    name: 'factorySetting',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_competitionId', type: 'uint256' }],
    name: 'getCompetitionData',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'id', type: 'uint256' },
          { internalType: 'uint256', name: 'startTime', type: 'uint256' },
          { internalType: 'uint256', name: 'endTime', type: 'uint256' },
          { internalType: 'address', name: 'poolAddress', type: 'address' },
          { internalType: 'address', name: 'projectToken', type: 'address' },
          { internalType: 'uint256', name: 'numberOfWinners', type: 'uint256' },
          { internalType: 'uint256', name: 'rewardAmount', type: 'uint256' },
          { internalType: 'address', name: 'rewardToken', type: 'address' },
          { internalType: 'address[]', name: 'winners', type: 'address[]' },
          { internalType: 'uint256[]', name: 'winnersAmount', type: 'uint256[]' },
          { internalType: 'bool', name: 'isBoosted', type: 'bool' },
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'bool', name: 'isVerified', type: 'bool' },
          { internalType: 'bool', name: 'isCore', type: 'bool' },
          { internalType: 'bool', name: 'isRewardSet', type: 'bool' },
          { internalType: 'uint256', name: 'competitionTypeIndex', type: 'uint256' },
        ],
        internalType: 'struct CadinuTradingCompetitionV2.Competitions',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
    name: 'getNumberOfAvailableCompetitionsForReward',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_user', type: 'address' },
      { internalType: 'uint256', name: '_length', type: 'uint256' },
    ],
    name: 'getUserAvailableCompetitionsForReward',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_user', type: 'address' },
      { internalType: 'uint256', name: '_length', type: 'uint256' },
    ],
    name: 'getUserCompetitionsAlreadyReceivedReward',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'nextId',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'operatorAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'referral',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'uint256', name: '_index', type: 'uint256' },
      { internalType: 'string', name: '_competitionTypeName', type: 'string' },
    ],
    name: 'setCompetitionType',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_competitionId', type: 'uint256' },
      { internalType: 'bool', name: '_status', type: 'bool' },
    ],
    name: 'setCoreForCompetition',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_operatorAddress', type: 'address' }],
    name: 'setOperator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_competitionId', type: 'uint256' },
      { internalType: 'address[]', name: '_users', type: 'address[]' },
      { internalType: 'uint256[]', name: '_rewards', type: 'uint256[]' },
    ],
    name: 'setReward',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_competitionId', type: 'uint256' },
      { internalType: 'bool', name: '_status', type: 'bool' },
    ],
    name: 'setVerificationForCompetition',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_startTime', type: 'uint256' },
      { internalType: 'uint256', name: '_endTime', type: 'uint256' },
      { internalType: 'address', name: '_poolAddress', type: 'address' },
      { internalType: 'address', name: '_projectToken', type: 'address' },
      { internalType: 'uint256', name: '_numberOfWinners', type: 'uint256' },
      { internalType: 'uint256', name: '_rewardAmount', type: 'uint256' },
      { internalType: 'address', name: '_rewardToken', type: 'address' },
      { internalType: 'address', name: '_referralAddress', type: 'address' },
      { internalType: 'uint256', name: '_competitionTypeIndex', type: 'uint256' },
    ],
    name: 'startCompetition',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'supportedFactories',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'verifiedOwners',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_owner', type: 'address' },
      { internalType: 'address', name: '_projectToken', type: 'address' },
    ],
    name: 'verifyOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'winnersToAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'withdrawAllReward', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [], name: 'withdrawETH', outputs: [], stateMutability: 'payable', type: 'function' },
  {
    inputs: [{ internalType: 'uint256[]', name: '_competitionIds', type: 'uint256[]' }],
    name: 'withdrawRewardForIds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { stateMutability: 'payable', type: 'receive' },
] as const
